* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}


html {
  font-family: source-sans-pro, sans-serif;
}

body {
  background-color: white;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  padding: 0rem 4rem;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  width: 100%;
  padding: 1rem;
}

section {
  margin-bottom: 1rem;
}

h1 {
  font-size: 3.5rem;
  display:inline;
  font-family: linotype-sabon, sans-serif;
  line-height: 1;
}

h2 {
  font-weight: 200;
  display:inline;
  font-size: 1.125rem;
}

p {
  font-size: 1.1rem;
  line-height: 1.5;
}

em {
  font-weight: 600;
}

a {
  text-decoration: none;
  color: black;
}

p a {
  text-decoration: underline;
}


section.intro {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.nav a {
  font-size: 1.2rem;
  margin-left: 1rem;
}


section.text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

section.text p {
  max-width: 500px;
}

.links {
  display: flex;
  justify-content: flex-end;
}

.btn {
  display: block;
  width: 10rem;
  text-align: center;
  text-decoration: none;
  padding: 0.4rem;
  color: black;
  margin-left: 1rem;
  background-color: white;
  font-family: linotype-sabon, sans-serif;
  border: 1px solid black;
  font-size: 1rem;
}

.btn:hover {
  font-style: italic;
  background-color: #f5ecec;
  // color: white;
}


section.video {
  margin: 2rem 0;
}

iframe {
  box-shadow: 0 0 12px -2px rgba(0,0,0,0.5);
}

.footer {
  font-size: 0.8rem;
  text-align: right;
  font-style: italic;
  margin-top: -1rem;
}
